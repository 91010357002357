import React from "react";
import { graphql } from "gatsby";

import ProductPage from "@/page-components/product";
import Layout from "@/layout";
import { SEO } from "@/layout/SEO";

const ProductTemplate = ({ data, pageContext, location }) => {
  const {
    title,
    description,
    primary,
    video,
    sections = [],
    footerCta,
    collectEmailForm,
  } = data.markdownRemark.frontmatter;

  return (
    <Layout footerCta={footerCta !== false} from="product">
      <ProductPage
        title={title}
        description={description}
        callToAction={primary?.callToAction}
        video={video}
        hash={pageContext.hash}
        sections={sections}
        collectEmail={collectEmailForm}
      />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...SeoFields
        primary {
          callToAction {
            primary {
              text
              link
            }
            secondary {
              text
              link
            }
          }
        }
        footerCta
        collectEmailForm
        sections {
          title
          background
          callToActions {
            text
            link
            type
          }
          markdown
          media {
            appFrame
            alternateText
            caption
            video
            screenshot {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: NONE
                  quality: 85
                  width: 650
                )
              }
            }
          }
          type
          gridItems {
            markdown
            appFrame
            alternateText
            gridImage {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: NONE
                  quality: 85
                  width: 650
                )
              }
            }
          }
          testimonials {
            ids
          }
          features {
            icon
            markdown
          }
        }
      }
    }
  }
`;

export const Head = ({ location, data, params, pageContext }) => {
  const {
    author,
    description,
    metaDescription,
    robots,
    schemaOrg,
    seoImage,
    title,
    titleTag,
  } = data.markdownRemark.frontmatter;

  return (
    <SEO
      author={author}
      description={description}
      metaDescription={metaDescription}
      pathname={location.pathname}
      robots={robots}
      schemaOrg={schemaOrg}
      seoImage={seoImage}
      title={title}
      titleTag={titleTag}
    />
  );
};

export default ProductTemplate;
